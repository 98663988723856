import { memo } from 'react';
import { FeatureFlagKey, featureFlag } from 'truckMapConfig/featureFlags';

export type FeatureEnabledProps = {
  name: FeatureFlagKey;
} & ChildrenProp;

export const StaticFeatureEnabled: React.ComponentType<FeatureEnabledProps> = memo(
  ({ name: featureName, children }) => (featureFlag[featureName] ? <>{children}</> : null)
);

StaticFeatureEnabled.displayName = 'FeatureEnabled';
