import { MapComponent } from 'components/common/MapComponent/MapComponent';
import { MapOptions } from 'components/common/MapComponent/MapComponentTypes';
import { useDesktopBreakpoint } from 'hooks/useUI/useDesktopBreakpoint';
import { makeStyles } from 'lib/makeStyles';
import React, { useMemo } from 'react';

const useRouteMapStyles = makeStyles({
  root: ['relative h-full'],
  loading: ['absolute', 'w-full h-full', 'z-map', 'bg-white opacity-20', 'animate-pulse'],
  loadingIcon: 'mr-2',
  map: ['h-full']
});

export const RouteMap = React.memo(() => {
  const styles = useRouteMapStyles();
  const isDesktop = useDesktopBreakpoint();

  const mapComponentProps = useMemo(
    () => ({
      mapStyle: styles.map,
      mapProps: {
        navigationOptions: {
          showCompass: false,
          showZoom: isDesktop
        },
        padding: {
          right: 0,
          left: isDesktop ? 410 : 0,
          top: isDesktop ? 0 : 200,
          bottom: 0
        },
        hasNavigation: true,
        options: { minZoom: 5 }
      },
      options: [
        MapOptions.MAP_RECENTER,
        MapOptions.ROUTE_PLANNER,
        MapOptions.MAP_FEATURES,
        MapOptions.MAP_EDITOR
      ]
    }),
    [isDesktop, styles.map]
  );

  return (
    <div className={styles.root}>
      <MapComponent {...mapComponentProps} />
    </div>
  );
});

RouteMap.displayName = 'RouteMap';
