import { IPlace, ITrip } from '@truckmap/common';
import { MakeMarkerProps } from 'hooks/useMap/useMapGL/useMapGLTripMarker';
import { NavigationControlProps } from 'react-map-gl';

export enum MapOptions {
  MAP_RECENTER = 'MAP_RECENTER',
  MAP_FEATURES = 'MAP_FEATURES',
  ROUTE_PLANNER = 'ROUTE_PLANNER',
  PLACES_SEARCH = 'PLACES_SEARCH',
  MAP_EXPAND = 'MAP_EXPAND',
  DISPATCH_PLANNER = 'DISPATCH_PLANNER',
  TRACKING = 'TRACKING',
  MAP_EDITOR = 'MAP_EDITOR'
}

type MapProps = {
  hasNavigation?: boolean;
  navigationOptions?: Partial<NavigationControlProps>;
  tabIndex?: number;
  padding?: { left: number; right: number; top: number; bottom: number };
  style?: React.CSSProperties;
  options?: Omit<mapboxgl.MapboxOptions, 'container'>;
};

type BaseMapComponentProps = {
  latitude?: IPlace['latitude'];
  longitude?: IPlace['longitude'];
  isLoading?: boolean;
  trip?: ITrip;
  mapStyle?: string;
  mapRecenterProps?: {
    className?: string;
  };
  mapProps?: MapProps;
  options?: MapOptions[];
  markerProps?: MakeMarkerProps;
};

export type MapComponentProps = BaseMapComponentProps;
