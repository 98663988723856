import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const EditIcon = React.memo((props: GlyphProps) => (
  <Glyph width={24} height={24} {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.75 19.25L9 18.25L18.2929 8.95711C18.6834 8.56658 18.6834 7.93342 18.2929 7.54289L16.4571 5.70711C16.0666 5.31658 15.4334 5.31658 15.0429 5.70711L5.75 15L4.75 19.25Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.25 19.25H13.75"
    />
  </Glyph>
));

EditIcon.displayName = 'EditIcon';

export { EditIcon };
