import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import { useMapEditorLayers } from 'hooks/useMap/useMapEditorLayers';
import { useMapEffect } from 'hooks/useMap/useMapGL/MapGLContext';
import { emptyFeature } from 'hooks/useMap/useRoutePlannerMap/useRoutePlannerMapSource';
import { LayerSpecification, SourceSpecification } from 'mapbox-gl';
import { useCallback } from 'react';

export enum MapEditorLayers {
  MARKERS = 'map-editor-markers'
}

export const useMapEditorSourcesAndLayers = () => {
  const controllerId = useMapControllerContext();
  const layers = useMapEditorLayers();

  const sources = new Map([
    [
      MapEditorLayers.MARKERS,
      {
        type: 'geojson',
        data: emptyFeature
      }
    ]
  ]);

  const memoizedEffect = useCallback(
    (mapInstance) => {
      mapInstance.addSource(MapEditorLayers.MARKERS, {
        type: 'geojson',
        data: emptyFeature
      } as SourceSpecification);

      layers.forEach((layer) => {
        if (!mapInstance.getLayer(layer.id)) {
          mapInstance.addLayer(layer as LayerSpecification);
        }
      });
    },
    [layers, sources]
  );

  useMapEffect(memoizedEffect, controllerId);
};
