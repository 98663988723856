import { MapEditorLayers } from 'hooks/useMap/useMapEditorSourcesAndLayers';
import { getIconImageExpression } from 'lib/mapbox';

const zoomThresholdMd = 12;
const zoomThresholdLg = 16;

const symbolLayout = {
  'icon-image': getIconImageExpression('image_key'),
  'icon-size': [
    'step',
    ['zoom'],
    // 0,
    // zoomThresholdSm, // At far zoom: no setting. (Keep this commented out.) Hiding icons at far zoom levels is handled on the `filter` property.
    ['/', ['get', 'icon_size_sm'], 128],
    zoomThresholdMd, // At medium zoom: small.
    ['/', ['get', 'icon_size_md'], 128],
    zoomThresholdLg, // At close zoom: normal.
    ['/', ['get', 'icon_size_lg'], 128]
  ],
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-rotation-alignment': 'viewport',
  'text-font': ['Inter Black'],
  'text-offset': [0, 0.08],
  'text-rotation-alignment': 'viewport',
  'text-field': [
    'step',
    ['zoom'],
    ['get', 'icon_text_abbrev'],
    zoomThresholdLg,
    ['get', 'icon_text']
  ],
  'text-size': [
    'step',
    ['zoom'],
    0,
    zoomThresholdMd,
    ['get', 'icon_text_size_sm'],
    zoomThresholdLg,
    ['get', 'icon_text_size_lg']
  ]
};

export const useMapEditorLayers = () => [
  {
    id: MapEditorLayers.MARKERS,
    type: 'symbol',
    source: MapEditorLayers.MARKERS,
    layout: symbolLayout,
    paint: {
      'text-color': ['get', 'icon_text_color'],
      'icon-opacity': [
        'interpolate',
        ['linear', 1],
        ['zoom'],
        6,
        ['case', ['boolean', ['feature-state', 'show-markers'], false], 0, 0],
        9,
        ['case', ['boolean', ['feature-state', 'show-markers'], false], 1, 1]
      ]
    }
  }
];
