import { RoadAttrFeatureType } from '@truckmap/common';
import { addMarkerEffect } from 'components/common/MapComponent/recoil/mapEditorEffects';
import { produce } from 'immer';
import { MapControllers } from 'models/MapControllers';
import { atomFamily, selectorFamily } from 'recoil';
import { routePlannerAtom } from 'recoil/routePlanner';

type MapEditorProps = {
  type: RoadAttrFeatureType;
  coords: number[];
  streetName: string;
  maxweight: string;
  maxheight: string;
  currentStep: number;
};

export const mapEditorAtomDefaultValue = {
  type: null,
  streetName: null,
  coords: [],
  maxweight: null,
  maxheight: null,
  currentStep: 0
};

export const mapEditorAtom = atomFamily<MapEditorProps, MapControllers>({
  key: 'mapEditorAtom',
  effects: (controllerId: MapControllers) => [addMarkerEffect(controllerId)],
  default: mapEditorAtomDefaultValue
});

export const mapEditorVisibleAtom = atomFamily<boolean, MapControllers>({
  key: 'mapEditorVisibleAtom',
  default: false
});

export const mapEditorVisibleSelector = selectorFamily<boolean, MapControllers>({
  key: 'mapEditorVisibleSelector',
  get:
    (controllerId) =>
    ({ get }) =>
      get(mapEditorVisibleAtom(controllerId)),
  set:
    (controllerId) =>
    ({ set, reset }, newValue) => {
      reset(mapEditorAtom(controllerId));
      set(mapEditorVisibleAtom(controllerId), newValue);
      set(
        routePlannerAtom(controllerId),
        produce((draft) => {
          draft.isPlannerVisible = !newValue;
        })
      );
    }
});
