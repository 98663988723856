import { buildFeature, RoadAttrFeature, RoadAttrFeatureProperties } from '@truckmap/common';
import { Feature, Point } from '@turf/helpers';
import { mapEditorAtomDefaultValue } from 'components/common/MapComponent/recoil/mapEditorAtom';
import { MapEditorLayers } from 'hooks/useMap/useMapEditorSourcesAndLayers';
import { getMapInstance } from 'hooks/useMap/useMapGL/MapGLContext';
import { lineAndIconFeatureProperties } from 'lib/map/featureTiles/getFeatureCollections';
import { GeoJSONSource } from 'mapbox-gl';
import { MapControllers } from 'models/MapControllers';

export const addMarkerEffect =
  (controllerId: MapControllers) =>
  ({ onSet }) =>
    onSet((newValue) => {
      const mapInstance = getMapInstance(controllerId);
      const { coords, maxweight, maxheight, type } = newValue;

      if (JSON.stringify(newValue) === JSON.stringify(mapEditorAtomDefaultValue)) {
        (mapInstance.getSource(MapEditorLayers.MARKERS) as GeoJSONSource).setData({
          type: 'FeatureCollection',
          features: []
        });
        mapInstance.flyTo({
          zoom: 8
        });
      }

      if (type) {
        mapInstance.flyTo({
          zoom: mapInstance.getZoom() > 15 ? mapInstance.getZoom() : 15
        });
      }

      if (!coords.length) return;

      const feature = buildFeature({
        coordinates: coords
      }) as unknown as Feature<
        Point,
        {
          type: string;
          imperial_display: string;
        }
      >;

      feature.properties = {
        type: type,
        imperial_display: maxweight || maxheight
      };

      const pointProperties = lineAndIconFeatureProperties(
        feature as unknown as Feature<RoadAttrFeature, RoadAttrFeatureProperties>,
        {
          equipmentHeights: maxweight,
          equipmentWeights: maxheight
        }
      );

      feature.properties = {
        ...feature.properties,
        ...pointProperties
      };

      (mapInstance.getSource(MapEditorLayers.MARKERS) as GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: [feature]
      });
    });
