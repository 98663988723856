import dynamic from 'next/dynamic';

export const MapOptionsLoader = {
  MapRecenter: dynamic(() => import('./MapRecenterOption').then((mod) => mod.MapRecenterOption)),
  MapFeatures: dynamic(() => import('./MapFeaturesOption').then((mod) => mod.MapFeaturesOption)),
  RoutePlanner: dynamic(() => import('./RoutePlannerOption').then((mod) => mod.RoutePlannerOption)),
  PlacesSearch: dynamic(() => import('./PlacesSearchOption').then((mod) => mod.PlacesSearchOption)),
  TrackingOptionCard: dynamic(() => import('./TrackingOption').then((mod) => mod.TrackingOption)),
  MapEditorOption: dynamic(() => import('./MapEditorOption').then((mod) => mod.MapEditorOption)),
  DispatchPlanner: dynamic(() =>
    import('./DispatchPlannerOption').then((mod) => mod.DispatchPlannerOption)
  ),
  TrackingRefreshButton: dynamic(() =>
    import('./TrackingOption').then((mod) => mod.TrackingOptionRefreshButton)
  )
};
