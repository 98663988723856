import { mapEditorVisibleSelector } from 'components/common/MapComponent/recoil/mapEditorAtom';
import { InfoTooltip } from 'components/common/TooltipNotifications';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { MapButton, MapButtonProps, useMapButtonStyles } from 'components/map/MapButton/MapButton';
import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import { useTranslation } from 'hooks/useContentful';
import { EditIcon } from 'icons/monochrome/EditIcon';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';
import { useSetRecoilState } from 'recoil';

export type MapEditorButtonProps = ParentClassNameProp & Pick<MapButtonProps, 'rounded' | 'show'>;

const useStyles = makeStyles<ParentClassNameProp>()({
  icon: (props) => ['text-neutralIcon', 'm-1/2 ', props.className]
});

export const MapEditorButton = memo(({ className, rounded, show = true }: MapEditorButtonProps) => {
  const mapButtonStyles = useMapButtonStyles();
  const mapController = useMapControllerContext();
  const styles = useStyles({ className: mapButtonStyles.icon });
  const { t } = useTranslation();
  const setMapEditorVisible = useSetRecoilState(mapEditorVisibleSelector(mapController));
  const toggleMapEditor = () => setMapEditorVisible((visible) => !visible);

  return (
    <InfoTooltip message="Map Editor" manualPosition="-left-12 top-[3px]" isSmall isSquare>
      <MapButton
        onClick={toggleMapEditor}
        rounded={rounded}
        show={show}
        className={className}
        aria-label={t('TRUCK_RESTRICTIONS_LAYERS')}>
        <PanelFlex padding={false} paddingX="XS" spacing="XS">
          <EditIcon className={styles.icon} />
        </PanelFlex>
      </MapButton>
    </InfoTooltip>
  );
});

MapEditorButton.displayName = 'MapEditorButton';
